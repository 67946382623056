<template>
    <div class="page-web page-bg cloud-mining">
        <Head />
        <div class="cloud-mining-top">
            <div class="cloud-mining-top-cont">
                <div class='cloud-mining-top-cont-left'>
                    <h2>ALPHA <strong>{{$t('cloud')}}</strong></h2>
                    <p>{{$t('cloudDesc')}}</p>
                </div>
                <div class='cloud-mining-top-cont-right'>
                    <img
                        src="@/assets/photo/cloud-top.png"
                        mode="scaleToFill"
                    />
                </div>
            </div>
        </div>
        <div class="cloud-mining-list">
            <ul class="menu-list">
                <li @click='() => checkTab(0)' :class="{active: tabIndex === 0 }">{{$t('clouding')}}</li>
                <li @click='() => checkTab(1)' :class="{active: tabIndex === 1 }">{{$t('cloudOrder')}}</li>
                <li @click='() => checkTab(2)' :class="{active: tabIndex === 2 }">{{$t('cloudMoney')}}</li>
            </ul>
            <div class="cloud-mining-list-cont">
            </div>
            <div class="cloud-mining-list-empty">
                <img src="@/assets/photo/empty.png" alt="">
                <p>{{$t('noCloud')}}</p>
            </div>
        </div>
        <Foot />
    </div>
</template>
<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
export default {
    name: 'cloudMining',
    components: {
        Head,
        Foot
    },
    data () {
        return {
            tabIndex: 0
        }
    },
    methods: {
        checkTab (index) {
            this.tabIndex = index
        }
    }
}
</script>

<style lang="scss">
.cloud-mining {
    min-width: 1200px;
    &-top {
        background-color: #2a2171;
        background-image: url('~@/assets/photo/home_back.png');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 358px 193px;
        width: 100%;
        height: 266px;
        &-cont {
            width: 1200px;
            margin: 0 auto;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--head-text-color);
            font-family: 'Avenir, sans-serif';
            font-size: 16px;
            &-left {
            max-width: 500px;
                & > h2 {
                    font-size: 42px;
                    margin-bottom: 30px;
                    & > strong {
                        color: rgb(253, 194, 17)
                    }
                }
            }
            &-right {
                & > img {
                    width: 210px;
                }
            }
        }
    }
    &-list {
        width: 1200px;
        margin: 0 auto;
        margin-top: 30px;
        color: var(--text-color);
        .menu-list {
            display: flex;
            align-items: center;
            font-size: 18px;
            list-style: none;
            padding: 0;
            margin: 0;
            & > li {
                height: 42px;
                margin-right: 12px;
                padding: 0 10px;
                line-height: 42px;
                cursor: pointer;
                &.active {
                    background: linear-gradient(270deg, #b039c2, #8b34e9 23%, #7632ff);
                    color: #fff;
                    border-radius: 24px;
                }
            }
        }
        &-cont {
        }
        &-empty {
            width: 100%;
            height: 380px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            & > p {
                margin-top: 16px;
            }
        }
    }
}
</style>